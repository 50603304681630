.services-container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  background-color: #ffffff;
  padding: 40px; /* This can be adjusted as needed */
  position: relative;
}

.services-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(28, 22, 12, 0.1);
  z-index: -1;
  border-radius: 20px;
}

.service-box {
  flex: 1;
  margin: 0 10px; /* Adjusted margin */
  background-color: #ffffff;
  border: 2px solid #707037; /* Brown border */
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease-in-out; /* Added for hover effect */
}

.service-box:hover {
  transform: translateY(-10px); /* Added for hover effect */
}

.service-icon {
  display: block;
  height: 40px;
  margin-top: -20px;
}

.service-content {
  padding: 20px; /* Adjusted padding */
}

.service-heading {
  font-size: 1.8em;
  font-weight: bold;
  color: #28a745;
  margin-bottom: 10px;
}

.service-description {
  font-size: 1em;
  color: #555;
  line-height: 1.6;
}

/* Responsive adjustments */
@media screen and (max-width: 1200px) {
  .services-container {
    flex-direction: column;
    padding: 20px; /* Adjusted padding */
  }

  .service-box {
    margin: 20px 0;
  }
}

@media screen and (max-width: 768px) {
  .service-box {
    height: auto; /* Ensures boxes adjust height based on content */
  }
}

@media screen and (max-width: 576px) {
  .services-container {
    padding: 10px; /* Further reduced padding for smaller screens */
  }

  .service-box {
    padding: 15px; /* Adjusted padding */
  }

  .service-heading {
    font-size: 1.5em; /* Reduced font size for headings */
  }

  .service-description {
    font-size: 0.9em; /* Reduced font size for descriptions */
  }
}
