.hero-carousel {
  height: 80vh;
  position: relative;
  overflow: hidden;
}

.hero-slide {
  position: relative;
}

.hero-slide img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  border: 5px solid green
}

.hero-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: right;
  color: white;
  z-index: 1;
}

.hero-text h4,
.hero-text h5 {
  margin: 0 0 10px;
}

.hero-button {
  display: inline-block;
  min-width: 84px;
  max-width: 240px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50px;
  height: 40px;
  padding: 0 20px;
  background-color: #019863;
  color: #FFFFFF;
  font-size: 1em;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

.hero-button:hover {
  background-color: #017a53;
}

@media (max-width: 1200px) {
  .hero-carousel {
    height: 70vh;
  }
  .hero-slide img {
    height: 70vh;
  }
}

@media (max-width: 992px) {
  .hero-carousel {
    height: 60vh;
  }
  .hero-slide img {
    height: 60vh;
  }
}

@media (max-width: 768px) {
  .hero-carousel {
    height: 50vh;
  }
  .hero-slide img {
    height: 50vh;
  }
}

@media (max-width: 576px) {
  .hero-carousel {
    height: 40vh;
  }
  .hero-slide img {
    height: 40vh;
  }
}
