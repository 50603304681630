
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply the global font to the entire website */
html, body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
