body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #ffffff; /* Slightly off-white background for better contrast */
}

.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  max-width: 1500px; /* Adjust max-width as needed */
  margin: 20px auto; /* Center the contact form */
  border-radius: 10px;
}

.contact-us h1 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #333333;
  text-align: center;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.form-container, .image-container {
  width: 50%; /* Adjust width to take half of the container */
}

.form-container {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content */
  align-items: center;
}

.form-container h2 {
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
}

form {
  width: 90%;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
  font-weight: bold;
  color: #333333;
}

input, textarea {
  width: 100%;
  padding: 15px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

input:focus, textarea:focus {
  border-color: #007bff;
  outline: none;
}

textarea {
  height: 150px;
  resize: vertical;
}

button {
  padding: 15px;
  font-size: 1em;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

button:hover {
  background-color: #0056b3;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.image-container img {
  width: 120%;
  height: 200px;
  border-radius: 10px;
  margin-left: 50px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .form-container, .image-container {
    width: 80%; /* Adjust width as needed */
    margin-bottom: 20px; /* Add margin at the bottom for spacing */
  }

  .contact-us h1 {
    font-size: 2em;
  }

  .form-container h2 {
    font-size: 1.5em;
  }
}

@media (max-width: 768px) {
  .form-container, .image-container {
    width: 90%; /* Adjust width as needed */
  }

  .contact-us h1 {
    font-size: 1.8em;
  }

  .form-container h2 {
    font-size: 1.4em;
  }
}

@media (max-width: 480px) {
  .form-container, .image-container {
    width: 100%; /* Full width on smaller screens */
  }

  .contact-us h1 {
    font-size: 1.5em;
  }

  .form-container h2 {
    font-size: 1.2em;
  }

  input, textarea {
    padding: 10px;
    font-size: 0.9em;
  }

  button {
    padding: 10px;
    font-size: 0.9em;
  }
}
