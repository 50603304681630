/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap');

/* Keyframes for animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* CountUp Section Styles */
.countup-section {
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(135deg, #ffffff, #ffffff); /* Background gradient */
}

.countup-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;
}

.countup-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px 30px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
  animation: fadeInUp 0.5s ease forwards;
}

.countup-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.countup-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: #A18249; /* Number color */
  font-family: 'Montserrat', sans-serif;
}

.countup-description {
  margin-top: 10px;
  font-size: 1rem;
  color: #1c160c; /* Description text color */
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .countup-cards {
    grid-template-columns: repeat(2, 1fr); /* Change to 2 columns for smaller screens */
  }

  .countup-card {
    padding: 15px 20px;
  }

  .countup-number {
    font-size: 2rem;
  }

  .countup-description {
    font-size: 0.9rem;
  }
}
