* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Set background color to white */
}

/* General Styles */
body {
  font-family: 'Arial', sans-serif;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #ffffff; /* Set background color to white */
}

.header {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.header-title {
  color: #1C160C;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
}

.header-title::after {
  content: '';
  display: block;
  width: 240px;
  height: 4px;
  background-color: #0a0a0a;
  margin: 0 auto;
  margin-top: 15px;
}

.header-description {
  color: #666;
  font-size: 1.2rem;
  line-height: 1.5;
  width: 85%;
  margin: 30px auto 0 auto; /* Center the description with auto margins */
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 80%;
}

.project-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #ffffff; /* Set background color to white */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.project-image {
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  transition: opacity 0.3s ease;
}

.project-title {
  font-size: 1rem;
  font-weight: 500;
  color: #1C160C;
  text-align: center;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 93, 14, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.8s ease, opacity 0.3s ease;
}

.project-card:hover .project-overlay {
  opacity: 1;
  transform: translateY(0);
}

.project-card:hover .project-image {
  opacity: 0.5;
}

.project-info {
  text-align: center;
}

.project-info h3 {
  margin: 0;
  font-size: 1.2rem;
}

.project-info p {
  margin: 5px 0;
  font-size: 1rem;
}

.other-projects-container {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}

.other-projects-slider {
  display: flex;
  overflow-x: auto;
  gap: 30px;
  padding: 16px 0;
}

.other-project-card {
  position: relative;
  flex: 0 0 auto;
  width: 700px;
  background-color: #FFFFFF; /* Set background color to white */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;
  text-align: center;
  height: 200px;
  overflow: hidden;
}

.other-project-image {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  margin-bottom: 8px;
  transition: opacity 0.3s ease;
  height: auto;
}

.other-project-card:hover .project-overlay {
  opacity: 1;
  transform: translateY(0);
}

.other-project-card:hover .project-image {
  opacity: 0.5;
}

.other-project-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1C160C;
}

.other-project-card .project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 93, 14, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.other-project-card .project-info {
  text-align: center;
}

.other-project-card .project-info h3 {
  margin: 0;
  font-size: 1.2rem;
}

.other-project-card .project-info p {
  margin: 5px 0;
  font-size: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .header-description {
    width: 90%;
    margin: 30px auto;
  }

  .other-projects-slider {
    padding: 16px 20px;
    margin-top: 20px;
  }

  .project-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    width: 90%;
  }

  .other-project-card {
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  .header-title {
    font-size: 1.8rem;
  }

  .header-description {
    width: 95%;
    margin: 20px auto;
    font-size: 1rem;
  }

  .project-card {
    padding: 12px;
  }

  .project-title {
    font-size: 0.875rem;
  }

  .project-info h3 {
    font-size: 1rem;
  }

  .project-info p {
    font-size: 0.875rem;
  }

  .other-project-card {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .header-title {
    font-size: 1.5rem;
  }

  .header-description {
    width: 100%;
    margin: 10px auto;
    font-size: 0.875rem;
  }

  .project-card {
    padding: 8px;
  }

  .project-title {
    font-size: 0.75rem;
  }

  .project-info h3 {
    font-size: 0.875rem;
  }

  .project-info p {
    font-size: 0.75rem;
  }

  .other-project-card {
    max-width: 250px;
  }
}
